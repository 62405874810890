const { locales, } = require("./locales");
const { BASE_URL } = require("./siteConfig");

const generateLocaleSource = (locale, path) => {
  return locale !== "default" ? `/${locale}${path}` : path;
};

const createRoute = (locale, path, title, description) => ({
  config: {
    rewrite: {
      source: generateLocaleSource(locale.hl, path),
      destination: path,
      locale: locale.hl,
    },
    redirect: {},
  },
  meta: {
    title,
    description,
    canonical: BASE_URL + generateLocaleSource(locale.hl, path),
  },
  sitemap: {
    pageName: "pages-sitemap.xml",
    changeFreq: "weekly",
    priority: "0.8",
  },
});

const routes = {};

if (locales.TR_LOCALE.active) {
  routes.tr = {
    "/": createRoute(locales.TR_LOCALE, "/", "Hüseyin Fehimli • Özel Yazılım Geliştirme & Dijital Pazarlama & E-ticaret Çözümleri & Prodüksiyon & Tasarım", "Hüseyin Fehimli, bireysel yazılım geliştirme, dijital pazarlama, e-ticaret çözümleri, üretim ve tasarım alanlarında uzmanlaşmıştır. İşletmeniz için özel yazılım çözümleri sunar, dijital pazarlama stratejileri geliştirir, e-ticaret siteleri kurar ve yönetir. Ayrıca üretim ve tasarım konularında da profesyonel hizmetler sağlar."),
    "/about": createRoute(locales.TR_LOCALE, "/about", "Hakkımızda | Hüseyin Fehimli", "Hüseyin Fehimli hakkında daha fazla bilgi edinin."),
    "/demo": createRoute(locales.TR_LOCALE, "/demo", "Demo Talep Et | Hüseyin Fehimli", "Demo talep etmek için hoş geldiniz."),
    "/team": createRoute(locales.TR_LOCALE, "/team", "Takım | Hüseyin Fehimli", "Hüseyin Fehimli ekibiyle tanışın."),
    "/careers": createRoute(locales.TR_LOCALE, "/careers", "Kariyer | Hüseyin Fehimli", "Hüseyin Fehimli'de kariyer fırsatlarını keşfedin."),
    "/our-references": createRoute(locales.TR_LOCALE, "/our-references", "Referanslarımız | Hüseyin Fehimli", "Hüseyin Fehimli referanslarını görün."),
    "/technologies": createRoute(locales.TR_LOCALE, "/technologies", "Teknolojiler | Hüseyin Fehimli", "Hüseyin Fehimli'nin kullandığı teknolojileri keşfedin."),
    "/commercial-information": createRoute(locales.TR_LOCALE, "/commercial-information", "Ticari Bilgiler | Hüseyin Fehimli", "Hüseyin Fehimli ticari bilgilerini öğrenin."),
    "/services": createRoute(locales.TR_LOCALE, "/services", "Hizmetler | Hüseyin Fehimli", "Hüseyin Fehimli'nin sunduğu hizmetleri keşfedin."),
    "/portfolio": createRoute(locales.TR_LOCALE, "/portfolio", "Portföy | Hüseyin Fehimli", "Hüseyin Fehimli'nin portföyünü inceleyin."),
    "/products": createRoute(locales.TR_LOCALE, "/products", "Ürünler | Hüseyin Fehimli", "Hüseyin Fehimli'nin ürünlerini keşfedin."),
    "/blog": createRoute(locales.TR_LOCALE, "/blog", "Blog | Hüseyin Fehimli", "Hüseyin Fehimli'nin blog yazılarını okuyun."),
    "/contact": createRoute(locales.TR_LOCALE, "/contact", "İletişim | Hüseyin Fehimli", "Hüseyin Fehimli ile iletişime geçin."),
    "/start-project": createRoute(locales.TR_LOCALE, "/start-project", "Proje Başlat | Hüseyin Fehimli", "Hüseyin Fehimli ile bir proje başlatın."),
    "/cookie-policy": createRoute(locales.TR_LOCALE, "/cookie-policy", "Çerez Politikası | Hüseyin Fehimli", "Hüseyin Fehimli'nin çerez politikasını okuyun."),
    "/privacy-policy": createRoute(locales.TR_LOCALE, "/privacy-policy", "Gizlilik Politikası | Hüseyin Fehimli", "Hüseyin Fehimli'nin gizlilik politikasını okuyun."),
    "/terms-of-service": createRoute(locales.TR_LOCALE, "/terms-of-service", "Hizmet Şartları | Hüseyin Fehimli", "Hüseyin Fehimli'nin hizmet şartlarını okuyun."),
    "/refund-policy": createRoute(locales.TR_LOCALE, "/refund-policy", "İade Politikası | Hüseyin Fehimli", "Hüseyin Fehimli'nin iade politikasını okuyun."),
    "/products/:id": createRoute(locales.TR_LOCALE, "/products/:id", "%s | Ürün Detayları | Hüseyin Fehimli", "Ürünlerimiz hakkında daha fazla bilgi edinin."),
    "/services/:id": createRoute(locales.TR_LOCALE, "/services/:id", "%s | Hizmet Detayları | Hüseyin Fehimli", "Hizmetlerimiz hakkında daha fazla bilgi edinin."),
    "/blog/:id": createRoute(locales.TR_LOCALE, "/blog/:id", "%s | Blog Yazısı | Hüseyin Fehimli", "Blog yazılarımızı okuyun."),
  };
}

if (locales.EN_UK_LOCALE.active) {
  routes.en = {
    "/": createRoute(locales.EN_UK_LOCALE, "/", "Hüseyin Fehimli • Custom Software Development & Digital Marketing & E-commerce Solutions & Production & Design", "Hüseyin Fehimli specializes in custom software development, digital marketing, e-commerce solutions, production, and design. He provides tailor-made software solutions for your business, develops digital marketing strategies, sets up and manages e-commerce sites. Additionally, he offers professional services in production and design."),
    "/about": createRoute(locales.EN_UK_LOCALE, "/about", "About Us | Hüseyin Fehimli", "Learn more about Hüseyin Fehimli."),
    "/demo": createRoute(locales.EN_UK_LOCALE, "/demo", "Request a Demo | Hüseyin Fehimli", "Welcome to request a demo."),
    "/team": createRoute(locales.EN_UK_LOCALE, "/team", "Team | Hüseyin Fehimli", "Meet the team behind Hüseyin Fehimli."),
    "/careers": createRoute(locales.EN_UK_LOCALE, "/careers", "Careers | Hüseyin Fehimli", "Explore career opportunities at Hüseyin Fehimli."),
    "/our-references": createRoute(locales.EN_UK_LOCALE, "/our-references", "Our References | Hüseyin Fehimli", "See our references at Hüseyin Fehimli."),
    "/technologies": createRoute(locales.EN_UK_LOCALE, "/technologies", "Technologies | Hüseyin Fehimli", "Discover the technologies we use at Hüseyin Fehimli."),
    "/commercial-information": createRoute(locales.EN_UK_LOCALE, "/commercial-information", "Commercial Information | Hüseyin Fehimli", "Learn about our commercial information at Hüseyin Fehimli."),
    "/services": createRoute(locales.EN_UK_LOCALE, "/services", "Services | Hüseyin Fehimli", "Discover the services we offer at Hüseyin Fehimli."),
    "/portfolio": createRoute(locales.EN_UK_LOCALE, "/portfolio", "Portfolio | Hüseyin Fehimli", "Explore our portfolio at Hüseyin Fehimli."),
    "/products": createRoute(locales.EN_UK_LOCALE, "/products", "Products | Hüseyin Fehimli", "Check out our products at Hüseyin Fehimli."),
    "/blog": createRoute(locales.EN_UK_LOCALE, "/blog", "Blog | Hüseyin Fehimli", "Read our latest blog posts at Hüseyin Fehimli."),
    "/contact": createRoute(locales.EN_UK_LOCALE, "/contact", "Contact | Hüseyin Fehimli", "Get in touch with us at Hüseyin Fehimli."),
    "/start-project": createRoute(locales.EN_UK_LOCALE, "/start-project", "Start a Project | Hüseyin Fehimli", "Start a project with us at Hüseyin Fehimli."),
    "/cookie-policy": createRoute(locales.EN_UK_LOCALE, "/cookie-policy", "Cookie Policy | Hüseyin Fehimli", "Read our cookie policy at Hüseyin Fehimli."),
    "/privacy-policy": createRoute(locales.EN_UK_LOCALE, "/privacy-policy", "Privacy Policy | Hüseyin Fehimli", "Read our privacy policy at Hüseyin Fehimli."),
    "/terms-of-service": createRoute(locales.EN_UK_LOCALE, "/terms-of-service", "Terms of Service | Hüseyin Fehimli", "Read our terms of service at Hüseyin Fehimli."),
    "/refund-policy": createRoute(locales.EN_UK_LOCALE, "/refund-policy", "Refund Policy | Hüseyin Fehimli", "Read our refund policy at Hüseyin Fehimli."),
    "/products/:id": createRoute(locales.EN_UK_LOCALE, "/products/:id", "%s | Product Details | Hüseyin Fehimli", "Discover more about our products."),
    "/services/:id": createRoute(locales.EN_UK_LOCALE, "/services/:id", "%s | Service Details | Hüseyin Fehimli", "Learn more about our services."),
    "/blog/:id": createRoute(locales.EN_UK_LOCALE, "/blog/:id", "%s | Blog Post | Hüseyin Fehimli", "Read our latest blog posts."),
  };
}

if (locales.DE_LOCALE.active) {
  routes.de = {
    "/": createRoute(locales.DE_LOCALE, "/", "Hüseyin Fehimli • Individuelle Softwareentwicklung, digitales Marketing, E-Commerce-Lösungen, Produktion und Design", "Hüseyin Fehimli ist spezialisiert auf individuelle Softwareentwicklung, digitales Marketing, E-Commerce-Lösungen, Produktion und Design. Er bietet maßgeschneiderte Softwarelösungen für Ihr Unternehmen, entwickelt digitale Marketingstrategien, richtet E-Commerce-Websites ein und verwaltet diese. Darüber hinaus bietet er professionelle Dienstleistungen in den Bereichen Produktion und Design an."),
    "/about": createRoute(locales.DE_LOCALE, "/about", "Über Uns | Hüseyin Fehimli", "Erfahren Sie mehr über Hüseyin Fehimli."),
    "/demo": createRoute(locales.DE_LOCALE, "/demo", "Demo Anfordern | Hüseyin Fehimli", "Willkommen zur Demo-Anforderung."),
    "/team": createRoute(locales.DE_LOCALE, "/team", "Team | Hüseyin Fehimli", "Treffen Sie das Team hinter Hüseyin Fehimli."),
    "/careers": createRoute(locales.DE_LOCALE, "/careers", "Karriere | Hüseyin Fehimli", "Entdecken Sie Karrieremöglichkeiten bei Hüseyin Fehimli."),
    "/our-references": createRoute(locales.DE_LOCALE, "/our-references", "Unsere Referenzen | Hüseyin Fehimli", "Sehen Sie sich unsere Referenzen bei Hüseyin Fehimli an."),
    "/technologies": createRoute(locales.DE_LOCALE, "/technologies", "Technologien | Hüseyin Fehimli", "Entdecken Sie die Technologien, die wir bei Hüseyin Fehimli verwenden."),
    "/commercial-information": createRoute(locales.DE_LOCALE, "/commercial-information", "Geschäftsinformationen | Hüseyin Fehimli", "Erfahren Sie mehr über unsere Geschäftsinformationen bei Hüseyin Fehimli."),
    "/services": createRoute(locales.DE_LOCALE, "/services", "Dienstleistungen | Hüseyin Fehimli", "Entdecken Sie die Dienstleistungen, die wir bei Hüseyin Fehimli anbieten."),
    "/portfolio": createRoute(locales.DE_LOCALE, "/portfolio", "Portfolio | Hüseyin Fehimli", "Entdecken Sie unser Portfolio bei Hüseyin Fehimli."),
    "/products": createRoute(locales.DE_LOCALE, "/products", "Produkte | Hüseyin Fehimli", "Entdecken Sie unsere Produkte bei Hüseyin Fehimli."),
    "/blog": createRoute(locales.DE_LOCALE, "/blog", "Blog | Hüseyin Fehimli", "Lesen Sie unsere neuesten Blog-Posts bei Hüseyin Fehimli."),
    "/contact": createRoute(locales.DE_LOCALE, "/contact", "Kontakt | Hüseyin Fehimli", "Kontaktieren Sie uns bei Hüseyin Fehimli."),
    "/start-project": createRoute(locales.DE_LOCALE, "/start-project", "Projekt Starten | Hüseyin Fehimli", "Starten Sie ein Projekt mit uns bei Hüseyin Fehimli."),
    "/cookie-policy": createRoute(locales.DE_LOCALE, "/cookie-policy", "Cookie-Richtlinie | Hüseyin Fehimli", "Lesen Sie unsere Cookie-Richtlinie bei Hüseyin Fehimli."),
    "/privacy-policy": createRoute(locales.DE_LOCALE, "/privacy-policy", "Datenschutzrichtlinie | Hüseyin Fehimli", "Lesen Sie unsere Datenschutzrichtlinie bei Hüseyin Fehimli."),
    "/terms-of-service": createRoute(locales.DE_LOCALE, "/terms-of-service", "Nutzungsbedingungen | Hüseyin Fehimli", "Lesen Sie unsere Nutzungsbedingungen bei Hüseyin Fehimli."),
    "/refund-policy": createRoute(locales.DE_LOCALE, "/refund-policy", "Rückerstattungsrichtlinie | Hüseyin Fehimli", "Lesen Sie unsere Rückerstattungsrichtlinie bei Hüseyin Fehimli."),
    "/products/:id": createRoute(locales.DE_LOCALE, "/products/:id", "%s | Produktdetails | Hüseyin Fehimli", "Erfahren Sie mehr über unsere Produkte."),
    "/services/:id": createRoute(locales.DE_LOCALE, "/services/:id", "%s | Dienstleistungsdetails | Hüseyin Fehimli", "Erfahren Sie mehr über unsere Dienstleistungen."),
    "/blog/:id": createRoute(locales.DE_LOCALE, "/blog/:id", "%s | Blog-Beitrag | Hüseyin Fehimli", "Lesen Sie unsere neuesten Blog-Posts."),
  };
}

module.exports = routes;
