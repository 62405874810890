import HeroSection from "../../components/HeroSection";

export default function CallToActionWithIllustration({ targetId }) {

  return (
    <>
      <HeroSection />
    </>
  );
}
