const locales = {
  TR_LOCALE: {
    name: "Turkish (Turkey)",
    hl: "tr",
    gl: "TR",
    dir: "ltr",
    hl_gl: "tr_TR",
    currency: "TRY",
    symbol: "₺",
    active: true,
  },
  DE_LOCALE: {
    name: "German (Germany)",
    hl: "de",
    gl: "DE",
    dir: "ltr",
    hl_gl: "de_DE",
    currency: "EUR",
    symbol: "€",
    active: true,
  },
  EN_UK_LOCALE: {
    name: "English (United Kingdom)",
    hl: "en",
    gl: "GB",
    dir: "ltr",
    hl_gl: "en_GB",
    currency: "GBP",
    symbol: "£",
    active: true,
  },
  EN_US_LOCALE: {
    name: "English (United States)",
    hl: "en",
    gl: "US",
    dir: "ltr",
    hl_gl: "en_US",
    currency: "USD",
    symbol: "$",
    active: false,
  },
  FR_LOCALE: {
    name: "French (France)",
    hl: "fr",
    gl: "FR",
    dir: "ltr",
    hl_gl: "fr_FR",
    currency: "EUR",
    symbol: "€",
    active: false,
  },
  ES_LOCALE: {
    name: "Spanish (Spain)",
    hl: "es",
    gl: "ES",
    dir: "ltr",
    hl_gl: "es_ES",
    currency: "EUR",
    symbol: "€",
    active: false,
  },
  IT_LOCALE: {
    name: "Italian (Italy)",
    hl: "it",
    gl: "IT",
    dir: "ltr",
    hl_gl: "it_IT",
    currency: "EUR",
    symbol: "€",
    active: false,
  },
  AR_LOCALE: {
    name: "Arabic (Saudi Arabia)",
    hl: "ar",
    gl: "SA",
    dir: "rtl",
    hl_gl: "ar_SA",
    currency: "SAR",
    symbol: "﷼",
    active: false,
  },
  ZH_LOCALE: {
    name: "Chinese (China)",
    hl: "zh",
    gl: "CN",
    dir: "ltr",
    hl_gl: "zh_CN",
    currency: "CNY",
    symbol: "¥",
    active: false,
  },
  JA_LOCALE: {
    name: "Japanese (Japan)",
    hl: "ja",
    gl: "JP",
    dir: "ltr",
    hl_gl: "ja_JP",
    currency: "JPY",
    symbol: "¥",
    active: false,
  },
};

const activeLocales = Object.values(locales)
  .filter(locale => locale.active)
  .reduce((acc, locale) => {
    acc[locale.hl] = locale.hl;
    return acc;
  }, {});

const activeCurrencies = Object.values(locales)
  .filter(locale => locale.active)
  .map(locale => locale.currency);

const getLocaleNameByHl = (hl) => {
  const localeKey = Object.keys(locales).find(key => locales[key].hl === hl);
  return localeKey ? locales[localeKey].name : hl.toUpperCase();
};



module.exports = {
  locales,
  activeLocales,
  activeCurrencies,
  getLocaleNameByHl,
};
