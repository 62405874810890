const path = require('path');
const { locales } = require('./locales');

const activeLocalesArray = Object.values(locales).filter(locale => locale.active);

module.exports = {
  i18n: {
    locales: activeLocalesArray.map(locale => locale.hl),
    defaultLocale: locales.TR_LOCALE.hl,
    localeDetection: false,
  },
  localePath: path.resolve('./public/locales'),
};
