 const API_BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://api.huseyinfehimli.com/"
    : "http://localhost:5226";

 const BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://huseyinfehimli.com"
    : "http://localhost:3000";


    module.exports={
        API_BASE_URL,
        BASE_URL
    }