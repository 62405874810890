import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";

import Head from "next/head";
import { site } from "../constants/site";

import { Box, Container, Flex } from "@chakra-ui/react";
import Cta from "../sections/Cta";

import dynamic from "next/dynamic";
import PageWrapper from "../components/Wrapper/PageWrapper";

const LazyOurServices = dynamic(() => import("../sections/OurServices"));

const LazyPortfolio = dynamic(() => import("../sections/Portfolio"));
const LazyOurReferences = dynamic(() => import("../sections/OurReferences"));

const LazyOurTechnologies = dynamic(() =>
  import("../sections/OurTechnologies")
);

const LazyWhyChooseUs = dynamic(() => import("../sections/WhyChooseUs"));
const LazyOurWorkProcess = dynamic(() => import("../sections/OurWorkProcess"));
const LazyBlog = dynamic(() => import("../sections/Blog"));

export default function HomePage() {
  const [isMounted, setIsMounted] = useState(false);

  const router = useRouter();

  let currentLang = "";

  useEffect(() => {
    setIsMounted(true);
    currentLang = document.documentElement.lang;
  }, []);

  const appizsoftOfficialDomainName = site.baseUrl;

  useEffect(() => {
    //  console.table(router);
  }, []);

  return (
    <PageWrapper
      currentPage=""
      /*"" yapmak index sayfası içindir*/ isCtaShow={false}
    >
      <Head>
        {/*
 <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@graph": [
                {
                  "@type": "WebPage",
                  "@id": site.baseUrl,
                  url: site.baseUrl,
                  name: site.title,
                  isPartOf: { "@id": site.baseUrl + "/#website" },
                  about: { "@id": site.baseUrl + "/#organization" },
                  datePublished: "2023-08-21T10:48:38+03:00",
                  dateModified: "2023-08-21T10:48:38+03:00",
                  description: site.description,
                  breadcrumb: { "@id": site.baseUrl + "/#breadcrumb" },
                  inLanguage: currentLang,
                  potentialAction: [
                    {
                      "@type": "ReadAction",
                      target: [site.baseUrl],
                    },
                  ],
                },
                {
                  "@type": "BreadcrumbList",
                  "@id": site.baseUrl + "/#breadcrumb",
                  itemListElement: [
                    { "@type": "ListItem", position: 1, name: site.title },
                  ],
                },
                {
                  "@type": "WebSite",
                  "@id": site.baseUrl + "/#website",
                  url: site.baseUrl,
                  name: site.title,
                  description: site.description,
                  publisher: { "@id": site.baseUrl + "/#organization" },
                  potentialAction: [
                    {
                      "@type": "SearchAction",
                      target: {
                        "@type": "EntryPoint",
                        urlTemplate:
                          site.baseUrl + "/search?s={search_term_string}",
                      },
                      "query-input": "required name=search_term_string",
                    },
                  ],
                  inLanguage: currentLang,
                },
                {
                  "@type": "Organization",
                  "@id": site.baseUrl + "/#organization",
                  name: site.title,
                  url: site.baseUrl,
                  logo: {
                    "@type": "ImageObject",
                    inLanguage: currentLang,
                    "@id": site.baseUrl + "/#/schema/logo/image/",
                    url: site.logoUrl,
                    contentUrl: site.logoUrl,
                    width: 458,
                    height: 145,
                    caption: site.title,
                  },

                  image: {
                    "@id": site.baseUrl + "/#/schema/logo/image/",
                  },
                  contactPoint: {
                    type: "ContactPoint",
                    telephone: site.telephone,
                    contactType: "customer service",
                    areaServed: "TR",
                    availableLanguage: "Turkish",
                  },

                  sameAs: site.sosyalMediaLinks.map((link) => link.link),
                },
              ],
            }),
          }}
        />
*/}
      </Head>

      <Container maxW="8xl" p={{ base: 5, md: 10 }}>
        <Flex direction={"column"} gap={10}>
          <Box id={"Cta"} as="section">
            <Cta targetId={"OurServices"} />
          </Box>
          <Box id={"OurServices"} as="section">
            {isMounted && <LazyOurServices targetId={"Technologies"} />}
          </Box>

          <Box id={"Portfolio"} as="section">
            {isMounted && <LazyPortfolio targetId={"Portfolio"} />}
          </Box>
          <Box id={"OurReference"} as="section">
            {isMounted && <LazyOurReferences targetId={"WhyChooseUs"} />}
          </Box>

          <Box id={"WhyChooseUs"} as="section">
            {isMounted && <LazyWhyChooseUs targetId={"OurWorkProcess"} />}
          </Box>

          <Box id={"OurWorkProcess"} as="section">
            {isMounted && <LazyOurWorkProcess targetId={"OurReferences"} />}
          </Box>

          <Box id={"Technologies"} as="section">
            {false && <LazyOurTechnologies targetId={"TrustedByDev"} />}
          </Box>

          <Box id={"Blog"} as="section">
            {isMounted && <LazyBlog targetId={"SSS"} />}
          </Box>
        </Flex>
      </Container>
    </PageWrapper>
  );
}
